export const navItems = [
  { name: "О Нас", link: "#about" },
  { name: "Ценности", link: "#projects" },
  { name: "Новости и Цены", link: "#testimonials" },
  { name: "Связаться", link: "#contact" },
];

export const gridItems = [
  {
    id: 1,
    title: "Мы заботимся о том, чтобы кофе выращивался только в органической среде ",
    description: "",
    className: "lg:col-span-3 md:col-span-6 md:row-span-4 lg:min-h-[60vh]",
    imgClassName: "w-full h-full",
    titleClassName: "justify-end",
    img: "/farm.svg",
    spareImg: "",
  },
  {
    id: 2,
    title: "Поставляем в любую точку мира. Прямо до двери.",
    description: "",
    className: "lg:col-span-2 md:col-span-3 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-start",
    img: "",
    spareImg: "",
  },
  {
    id: 3,
    title: "Инновации",
    description: "Мы всегда ищем новые способы обработки кофе",
    className: "lg:col-span-2 md:col-span-3 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-center",
    img: "",
    spareImg: "",
  },
  {
    id: 4,
    title: "Наша страсть - кофе, выращенный в органике",
    description: "",
    className: "lg:col-span-2 md:col-span-3 md:row-span-1",
    imgClassName: "",
    titleClassName: "justify-start",
    img: "/grid.svg",
    spareImg: "/b4.svg",
  },
  
  {
    id: 5,
    title: "Сейчас мы внедряем самые передовые технологии",
    description: "ИИ и Блокчейн",
    className: "md:col-span-3 md:row-span-2",
    imgClassName: "absolute right-0 bottom-0 md:w-96 w-60",
    titleClassName: "justify-center md:justify-start lg:justify-center",
    img: "/chain.svg",
    spareImg: "/grid.svg",
  },
  {
    id: 6,
    title: "Хотите, чтобы мы вам привезли кофейку?",
    description: "",
    className: "lg:col-span-2 md:col-span-3 md:row-span-1",
    imgClassName: "",
    titleClassName: "justify-center md:max-w-full max-w-60 text-center",
    img: "",
    spareImg: "",
  },
];
 
export const projects = [
  {
    id: 1,
    title: "Прямое управление фермами",
    des: "Мы управляем фермами напрямую, гарантируя высокое качество кофе.",
    img: "/point1.svg",
    iconLists: ["/robustabean.png", "/arabicabean.png", "/roastedbean.png", "/instant.png", "/3in1.png"],
    link: "/ui.earth.com",
  },
  {
    id: 2,
    title: "Устойчивые технологии",
    des: "Используем только экологически чистые и устойчивые технологии.",
    img: "/point2.svg",
   iconLists: ["/robustabean.png", "/arabicabean.png", "/roastedbean.png", "/instant.png", "/3in1.png"],
    link: "/ui.yoom.com",
  },
  {
    id: 3,
    title: "Справедливая торговля",
    des: "Справедливое вознаграждение для фермеров за их усердный труд.",
    img: "/point3.svg",
    iconLists: ["/robustabean.png", "/arabicabean.png", "/roastedbean.png", "/instant.png", "/3in1.png"],
    link: "/ui.aiimg.com",
  },
  {
    id: 4,
    title: "Исключительное качество",
    des: "Лучшие сорта робусты, арабики, мокки и лювака ",
    img: "/point4.svg",
    iconLists: ["/robustabean.png", "/arabicabean.png", "/roastedbean.png", "/instant.png", "/3in1.png"],
    link: "/ui.apple.com",
  },
];

export const testimonials = [
  {
    quote:
      "VietCoffee.Shop объявил о запуске новой линейки органического кофе. Новые продукты обещают высокое качество и устойчивое производство, что удовлетворит даже самых требовательных ценителей кофе.",
    name: "VietCoffee.Shop Запускает Новую Линейку Органического Кофе",
    title: " ",
  },
  {
    quote:
      "В стремлении увеличить производственные мощности и улучшить логистику, VietCoffee.Shop открыл новую фабрику в Центральном Вьетнаме. Это позволит компании быстрее и эффективнее обслуживать своих клиентов по всему миру",
    name: "VietCoffee.Shop Открывает Новую Фабрику в Центральном Вьетнаме",
    title: " ",
  },
  {
    quote:
      "VietCoffee.Shop был удостоен престижной награды за высокое качество своей продукции на международной выставке кофе. Это признание подчеркивает стремление компании к совершенству и удовлетворению потребностей своих клиентов.",
    name: "VietCoffee.Shop Получает Престижную Награду за Качество",
    title: " ",
  },
  {
    quote:
      "В рамках инновационного подхода к производству, VietCoffee.Shop начал внедрение технологий искусственного интеллекта для оптимизации процессов обработки и обжарки кофе. Это поможет повысить эффективность и качество продукции.",
    name: "VietCoffee.Shop Внедряет Технологии AI для Оптимизации Производства",
    title: "  ",
  },
  {
    quote:
      "VietCoffee.Shop объявил о запуске инициативы по поддержке малых фермеров. В рамках этой программы компания будет предоставлять образовательные ресурсы и финансовую помощь, способствуя устойчивому развитию кофейной индустрии в регионе.",
    name: "VietCoffee.Shop Запускает Инициативу по Поддержке Малых Фермеров",
    title: "  ",
  },
];
  

export const companies = [
  {
    id: 1,
    name: "Robusta",
    img: "/robustabean.png",
    nameImg: "/robustaprice.png",
    link: "https://docs.google.com/document/d/1_UpzsQ1OfneEfSAtFjhW_SXpcpVYdnxvacAZoN1HDhQ/edit?usp=drive_link",
  },
  {
    id: 2,
    name: "Arabica",
    img: "/arabicabean.png",
    nameImg: "/arabicaprice.png",
    link: "https://docs.google.com/document/d/12s-rKzu4HGChikx1DZd_aDEvhrO2KhJV8BPeleZXU4E/edit?usp=sharing",
  },
  {
    id: 3,
    name: "Roasted",
    img: "/roastedbean.png",
    nameImg: "/roastedprice.png",
    link: "https://docs.google.com/document/d/1Zfr0cgREsPFauefbfmEdTju1pWFczsg1/edit?usp=drive_link&ouid=108560611931802669610&rtpof=true&sd=true",
  },
  {
    id: 4,
    name: "Instant",
    img: "/instant.png",
    nameImg: "/instantprice.png",
    link: "https://docs.google.com/document/d/1Zf_VbqusZgSHqqb7WESX3PDANuA-lFf2sjnWW2fdmYY/edit?usp=sharing",
  },
  {
    id: 5,
    name: "3in1",
    img: "/3in1.png",
    nameImg: "/3in1price.png",
    link: "https://docs.google.com/document/d/1fjxON9skYwUdPe_7QNRK4BjcjGnWMJFz3p5rqixDzxU/edit?usp=sharing",
  },
];

export const workExperience = [
  {
    id: 1,
    title: "ИИ для использования воды и анализа почвы",
    desc: "Использование решений на основе ИИ для оптимизации ирригационных систем и проведения комплексного анализа здоровья почвы, что улучшает урожайность и устойчивость.",
    className: "md:col-span-2",
    thumbnail: "/exp1.svg",
  },
  {
    id: 2,
    title: "Страхование урожая на блокчейне с оракулами",
    desc: "Внедрение смарт-контрактов на блокчейне и оракулов для страхования урожая, обеспечивая прозрачное и надежное покрытие различных рисков.",
    className: "md:col-span-2", // change to md:col-span-2
    thumbnail: "/exp2.svg",
  },
  {
    id: 3,
    title: "Закупка кофе на блокчейне",
    desc: "Использование блокчейн-технологии для токенизации кофе как сырья, упрощая процессы закупок и обеспечивая отслеживаемость продукции от фермы до чашки.",
    className: "md:col-span-2", // change to md:col-span-2
    thumbnail: "/exp3.svg",
  },
  {
    id: 4,
    title: "Улучшенная обработка и обжарка кофе",
    desc: "Применение передовых технологий для обработки сырых кофейных зерен и обжарки, что обеспечивает высокое качество и уникальный вкус кофе.",
    className: "md:col-span-2",
    thumbnail: "/exp4.svg",
  },
];

export const socialMedia = [
  {
    id: 1,
    img: "/git.svg",
  },
  {
    id: 2,
    img: "/twit.svg",
  },
  {
    id: 3,
    img: "/link.svg",
  },
];
